<template>
  <div :class="priceColor">
    {{ colPaid }}
  </div>
</template>
<script>
import {isNotUndefinedAndNotNull} from "@/utils/classes";

export default {
  props: {
    colPaid: {
      type: String,
      default: null
    },
    colToPay: {
      type: String,
      default: null
    }
  },
  computed: {
    priceColor() {
      if (isNotUndefinedAndNotNull(this.colToPay) && isNotUndefinedAndNotNull(this.colPaid)) {
        const paidTabString = parseFloat(this.colPaid.split(this.$currency)[0]);
        const toPayTabString = parseFloat(this.colToPay.split(this.$currency)[0]);

        if (paidTabString >= toPayTabString) {
          return 'paid';
        }
        if (paidTabString === 0 && toPayTabString > 0) {
          return 'not-paid';
        }

        return 'waiting-paid';
      }

      return '';
    }
  }
}
</script>
<style lang="scss" scoped>
.paid {
  color: #43946e;
}

.waiting-paid {
  color: #e99040;
}

.not-paid {
  color: #FF0101;
}
</style>
